import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_BASE } from '../util/api';
import { Store } from '@ngrx/store';
import { SchedulerFeature } from '../state/scheduler.feature';
import { Schedule, Task } from '../models/schedule.struct';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {
  private http = inject(HttpClient);
  private store = inject(Store);
  readonly schedules$ = this.store.select(SchedulerFeature.selectSchedules);

  public getMySchedules(): Observable<Schedule[]> {
    return this.http.get<any[]>(`${API_BASE}/schedules/`);
  }

  public getMyTasks(): Observable<Task[]> {
    return this.http.get<any[]>(`${API_BASE}/tasks/`);
  }

  public createSchedule(): Observable<any> {
    return this.http.post(`${API_BASE}/schedules/`, {});
  }

  private sanitizeSchedule(schedule: Schedule): Partial<Schedule> {
    // ? Removes `tasks[]` from the schedule as they are updated separately
    // ? This is to prevent them from being overwritten by an empty array.
    const { tasks, ...sanitizedSchedule } = schedule;
    return sanitizedSchedule;
  }

  public updateSchedule(schedule: Schedule): Observable<any> {
    return this.http.patch(`${API_BASE}/schedules/${schedule.id}/`, this.sanitizeSchedule(schedule));
  }

  public deleteSchedule(id: number): Observable<any> {
    return this.http.delete(`${API_BASE}/schedules/${id}/`);
  }

  public createTask(scheduleId: number): Observable<any> {
    return this.http.post(`${API_BASE}/tasks/`, {"schedule_id": scheduleId});
  }

  public deleteTask(taskId: number): Observable<any> {
    return this.http.delete(`${API_BASE}/tasks/${taskId}/`);
  }

  public updateTask(task: Task): Observable<Task> {
    return this.http.patch<Task>(`${API_BASE}/tasks/${task.id}/`, task);
  }
}
