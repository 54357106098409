<div class="sdn p-8 bwu-ch-border">
  <div class="flex flex-row">
    <div class="font-semibold text-3xl col-span-6">scripts</div>
    <div class="ml-auto">
      <p-inputgroup>
        <input pInputText placeholder="Search" class="w-[300px]" [formControl]="search" (keyup.enter)="doSearch()"/>
          <p-inputgroup-addon>
              <p-button icon="pi pi-search" severity="secondary" variant="text" (click)="doSearch()"/>
          </p-inputgroup-addon>
      </p-inputgroup>
    </div>
  </div>

  <div class="my-4">
    <p-divider></p-divider>
  </div>

  <div class="flex flex-col w-full">
    <!-- <div *ngIf="(latest$ | async) as product" class="card mt-4">
      <div class="featured-header text-2xl font-semibold ml-2">Latest Release</div>
      <div class="border border-surface-200 dark:border-surface-700 rounded m-2 p-4">
        <div class="mb-4">
          <div class="relative mx-auto w-[300px] h-[200px] overflow-hidden">
            <img src="http://localhost:8000{{ product.image }}" [alt]="product.name"
              class="w-[300px] h-[200px] rounded-border" />
          </div>
        </div>
        <div class="mb-4 font-medium">
          {{ product.name }}
        </div>
        <div class="flex justify-between items-center">
          <div class="mt-0 font-semibold text-xl">
            {{ '$' + product.price }}
          </div>
          <span>
            <p-button icon="pi pi-heart" severity="secondary" [outlined]="true" />
            <p-button icon="pi pi-shopping-cart" styleClass="ml-2" />
          </span>
        </div>
      </div>

    </div> -->

    <!-- <div class="featured w-full">
      <div class="featured-header text-2xl font-semibold ml-[3.25rem]">Featured</div>
      <app-script-carousel></app-script-carousel>
    </div> -->

    <!-- If I decide to not use the divider here, remove the div just above here and re-add px-12 to div below -->
    <!-- <p-divider></p-divider> -->
    <div class="grid grid-flow-row-dense grid-cols-4">
      @for (script of (sdnService.scripts$ | async); track script) {

      <div
        class="card border border-surface rounded-border m-2 p-4 w-[340px] h-[334px]"
      >
        <div class="mb-4">
          <div class="relative mx-auto">
            <img
              src="http://localhost:8000{{ script.image }}"
              [alt]="script.name"
              class="w-full mx-auto rounded-border w-[300px] h-[200px]"
            />
          </div>
        </div>
        <div class="mb-4 font-medium">
          {{ script.name }}
        </div>
        <div class="flex justify-between items-center">
          <div class="mt-0 font-semibold text-xl">
            {{ "$" + script.price }}
          </div>
          <span>
            <p-button
              icon="pi pi-heart"
              severity="secondary"
              [outlined]="true"
            />
            <p-button
              icon="pi pi-shopping-cart"
              styleClass="ml-2"
              (click)="addToCart(script.id)"
            />
          </span>
        </div>
      </div>
      }
    </div>
  </div>
</div>
