<div class="mb-2">
    <p-fluid>
        <div class="flex flex-row space-x-2">
            <!-- <div class="col-span-2">
                <p-floatlabel variant="on" class="w-full">
                    <input pInputText type="text" id="name" [formControl]="name" autocomplete="off"
                        class="w-full p-3 text-base" />
                    <label for="name">Name</label>
                </p-floatlabel>
            </div> -->




            <div class="">
                <p-select class="w-[200px]" [options]="dropDownItems" [(ngModel)]="selectedItem" optionLabel="name"
                    id="scriptOptions" placeholder="Select script" />
            </div>

            <div class="col-span-1">
                <p-floatlabel variant="on" class="w-full">
                    <input pInputText type="text" id="weighting" [formControl]="weighting" autocomplete="off"
                        class="w-full p-3 text-base" />

                    <label for="weighting">Weighting</label>
                </p-floatlabel>
            </div>

            <div class="col-span-1">
                <p-floatlabel variant="on" class="w-full">
                    <input pInputText type="text" id="minRunTime" [formControl]="minRunTime" autocomplete="off"
                        class="w-full p-3 text-base" />
                    <label for="minRunTime">Min Run Time</label>
                </p-floatlabel>
            </div>

            <div class="col-span-1">
                <p-floatlabel variant="on" class="w-full">
                    <input pInputText type="text" id="maxRunTime" [formControl]="maxRunTime" autocomplete="off"
                        class="w-full p-3 text-base" />
                    <label for="maxRunTime">Max Run Time</label>
                </p-floatlabel>
            </div>

            <div class="controls flex flex-row space-x-4">
                <div (click)="updateTask()" class="flex justify-center items-center">
                    <span class="pi pi-check-circle hover:text-primary hover:cursor-pointer"></span>
                </div>

                <!-- <div><p-divider layout="vertical" /></div> -->

                <div (click)="deleteTask()" class="flex justify-center items-center">
                    <span class="pi pi-minus-circle hover:text-red-500 hover:cursor-pointer"></span>
                </div>
            </div>
        </div>
    </p-fluid>
</div>