import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { API_BASE } from '../util/api';
import { Observable } from 'rxjs';
import { Script, ScriptConfig } from '../models/script.struct';
import { SCPFeature, SdnFeature } from '../state/sdn.feature';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class SdnService {
  private readonly http = inject(HttpClient);
  private store = inject(Store);
  // public scpScripts$ = this.store.select(SCPFeature.selectScriptConfigs);
  public authoredScripts$ = this.store.select(SCPFeature.selectScripts);
  public scripts$ = this.store.select(SdnFeature.selectScripts);

  getAllScripts(): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}/sdn/`);
  }

  getFeatured(): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}/sdn/featured/`);
  }

  getLatest(): Observable<Script> {
    return this.http.get<Script>(`${API_BASE}/sdn/latest/`);
  }

  getMySubscriptions(): Observable<Script[]> {
    return this.http.get<Script[]>(`${API_BASE}/sdn/subs/`);
  }

  getScripterControlList(): Observable<Script[]> {
    return this.http.get<Script[]>(`${API_BASE}/sdn/control/list/`);
  }

  patchScriptConfiguration(scriptConfig: ScriptConfig): Observable<ScriptConfig> {
    return this.http.patch<ScriptConfig>(`${API_BASE}/sdn/control/config/`, scriptConfig);
  };

  searchScripts(search: string): Observable<Script[]> {
    return this.http.post<Script[]>(`${API_BASE}/sdn/search/`, { search });
  }
}
