import { Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { UserService } from '../../services/user.service';
import { AsyncPipe } from '@angular/common';
import { AuthFeature } from '../../state/auth.feature';
import { Store } from '@ngrx/store';



@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, ButtonModule, AsyncPipe],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  public userService = inject(UserService);
  private store = inject(Store);
  public user$ = this.store.select(AuthFeature.selectUser);

  ngOnInit(): void {
    this.user$.subscribe(user => {
      if (user?.is_scripter) {
        this.items.push({
          label: "Scripter",
          icon: "pi pi-cog",
          routesTo: "control/scripts",
          requiresAuth: true
        });
      }
    });
  }

  public items = [
    {
      label: "Subscription",
      icon: "pi pi-chart-bar",
      routesTo: "subscriptions",
      requiresAuth: false 
    },
    {
      label: "Community",
      icon: "pi pi-users",
      routesTo: "forum",
      requiresAuth: false 
    },
    {
      label: "Scripts",
      icon: "pi pi-code",
      routesTo: "scripts",
      requiresAuth: false 
    },
    {
      label:" Scheduler",
      icon: "pi pi-clock",
      routesTo: "scheduler",
      requiresAuth: true
    },
  ]
}
