import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Script } from '../../models/script.struct';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FluidModule } from 'primeng/fluid';
import { InputTextModule } from 'primeng/inputtext';
import { FloatLabelModule } from 'primeng/floatlabel';
import { DividerModule } from 'primeng/divider';
import { IftaLabelModule } from 'primeng/iftalabel';
import { SdnService } from '../../services/sdn.service';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { SelectModule } from 'primeng/select';
import { InputSwitchModule } from 'primeng/inputswitch';

@Component({
  selector: 'app-script-config',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    FluidModule,
    InputTextModule,
    ReactiveFormsModule,
    FloatLabelModule,
    DividerModule,
    IftaLabelModule,
    ButtonModule,
    RippleModule,
    InputSwitchModule
  ],
  templateUrl: './script-config.component.html',
  styleUrl: './script-config.component.scss'
})
export class ScriptConfigComponent implements OnInit, OnChanges {
  @Input() scriptId!: number;
  public sdnService = inject(SdnService);
  public maxSlots = new FormControl('');
  public maxRuntimeHours = new FormControl('');
  public maxSessions = new FormControl('');
  public debugMode = new FormControl(false);

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['scriptId']) {
      this.changeFocusedScript(this.scriptId);
    }
  }

  private changeFocusedScript(id: number): void {
    this.sdnService.authoredScripts$.subscribe((scripts) => {
      const script = scripts.find((s) => s.id === id);
      if (script) {
        this.maxSlots.setValue(script.config?.max_slots?.toString() ?? '');
        this.maxRuntimeHours.setValue(script.config?.max_runtime_hours?.toString() ?? '');
        this.maxSessions.setValue(script.config?.max_instances?.toString() ?? '');
        this.debugMode.setValue(script.config?.debug_mode ?? false);
      }
    });
  }

  toggleDebugMode(): void {
    this.debugMode.setValue(!this.debugMode.value);
  }
}
