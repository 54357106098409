<!-- <div class="rounded-lg p-4 bg-zinc-900 grid grid-cols-6 gap-4">
    <div class="font-semibold text-3xl col-span-6">
        <span style="font-size: 2rem;" class="text-primary">[</span>
        <span>control</span>
        <span style="font-size: 2rem;" class="text-primary">]</span>
    </div>

    <p-divider class="col-span-6"></p-divider>


</div> -->

<div class="glass">
    <p-splitter
        [style]="{ height: '300px' }"
        [panelSizes]="[20, 80]"
        styleClass="mb-8"
    >
        @if (sdnService.authoredScripts$ | async; as scripts)
        {
        <ng-template pTemplate>
            <!-- TODO: Scrolling -->
            <div class="flex py-2 px-2 h-full">
                    <div class="flex flex-col pr-2">
                        @for (script of scripts; track script; let i = $index) 
                        {
                        <div class="sc px-3 py-1 cursor-pointer transition-all"
                            [ngClass]="{'active-sc': i == selectedScript}" (click)="changeSelectedScript(i)">
                            <div class="text-lg">{{script.name}}</div>
                        </div>
                        }
                    </div>
            </div>
        </ng-template>
        <ng-template pTemplate>
            <div class="flex py-0 px-4 h-full w-full">
                <app-script-config [scriptId]="scripts[selectedScript].id"></app-script-config>
            </div>
        </ng-template>
        }
    </p-splitter>
</div>