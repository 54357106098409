<p-fluid>
  <div class="grid grid-cols-6 gap-4 mb-1">
    <div class="col-span-2">
      <p-floatlabel variant="on" class="w-full">
        <input pInputText type="text" id="name" [formControl]="name" autocomplete="off" class="w-full p-3 text-base" />
        <label for="name">Name</label>
      </p-floatlabel>
    </div>

    <div class="col-span-2">
      <p-select class="min-w-full" [options]="exitStrategies" [(ngModel)]="exitStrategy" optionLabel="name"
        id="exitStrategy" placeholder="Select an exit strategy" />
    </div>

    <div class="col-span-1">
      <p-floatlabel variant="on" class="w-full">
        <input pInputText type="text" id="intervalMin" [formControl]="intervalMin" autocomplete="off"
          class="w-full p-3 text-base" />

        <label for="intervalMin">Interval Min.</label>
      </p-floatlabel>
    </div>

    <div class="col-span-1">
      <p-floatlabel variant="on" class="w-full">
        <input pInputText type="text" id="intervalMax" [formControl]="intervalMax" autocomplete="off"
          class="w-full p-3 text-base" />
        <label for="intervalMax">Interval Max.</label>
      </p-floatlabel>
    </div>
    <p-button pRipple class="col-span-1 my-1 font-semibold" size="large" (click)="saveSchedule()">Save</p-button>
    <p-button pRipple class="col-span-1 my-1 font-semibold" size="large" severity="contrast"
      (click)="deleteSchedule()">Delete</p-button>
  </div>
</p-fluid>