import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { User, UserFeatureActions } from '../state/user.feature';
import { API_BASE } from '../util/api';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthActions, AuthFeature } from '../state/auth.feature';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly http = inject(HttpClient);
  private store = inject(Store);
  public loggedInUser$ = this.store.select(AuthFeature.selectUser);

  getUserById(id: number) {
    return this.http.get<User>(`${API_BASE}/members/${id}/`);
  }

  postRegistration(data: {
    username: string;
    password: string;
  }): Observable<any> {
    return this.http.post(`${API_BASE}/members/new/`, data);
  }

  postLogin(data: { username: string, password: string }): Observable<any> {
    return this.http.post(`${API_BASE}/token/`, data);
  }

  getSelf(): Observable<any> {
    return this.http.get(`${API_BASE}/members/me/`);
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('ILoveModTwisted');
  }

  hydrateUser(): void {
    this.getSelf().subscribe((user: any) => {
      this.store.dispatch(AuthActions.setUser({ user }));
      this.store.dispatch(UserFeatureActions.set({ user }));
    });
  }
}
