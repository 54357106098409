import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, inject, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';

import { providePrimeNG } from 'primeng/config';
import Lara from '@primeng/themes/lara';
import { provideStore, Store, StoreModule } from '@ngrx/store';
import { UserFeature } from './shared/state/user.feature';
import { provideEffects } from '@ngrx/effects';
import { UserEffects } from './shared/state/user.effects';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { AuthActions, AuthFeature } from './shared/state/auth.feature';
import { AuthEffects } from './shared/state/auth.effects';
import { authInterceptor } from './auth.intercept';
import { CartFeature } from './shared/state/cart.feature';
import { SchedulerFeature } from './shared/state/scheduler.feature';
import { SCPFeature, SdnFeature } from './shared/state/sdn.feature';
import { SdnEffects } from './shared/state/sdn.effects';
import { MessageService } from 'primeng/api';
import { SchedulerEffects } from './shared/state/scheduler.effects';
import { CartEffects } from './shared/state/cart.effects';
import { NgxStripeModule } from 'ngx-stripe';


function rehydrateToken() {
  return () => {
    const token = localStorage.getItem('authToken');
    if (token) {
      const store = inject(Store);
      store.dispatch(AuthActions.setToken({ token }));
    }
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: Lara,
      },
    }),
    provideStore(),
    MessageService,
    importProvidersFrom(
      StoreModule.forRoot({}),
      StoreModule.forFeature(UserFeature),
      StoreModule.forFeature(AuthFeature),
      StoreModule.forFeature(CartFeature),
      StoreModule.forFeature(SchedulerFeature),
      StoreModule.forFeature(SdnFeature),
      StoreModule.forFeature(SCPFeature),
    ),
    provideEffects(UserEffects),
    provideEffects(AuthEffects),
    provideEffects(SdnEffects),
    provideEffects(CartEffects),
    provideEffects(SchedulerEffects),
    importProvidersFrom(NgxStripeModule.forRoot('pk_test_51ISu3QKP1sH7T3SbUh8i8hb0RkLw7RcZ2SPDFyze2l1Trkdf4t9fnOfpA32eOvAS5fgQ91y50mHR3JPr2yOA9UZV00sAvJbnyI')),
    {
      provide: APP_INITIALIZER,
      useFactory: rehydrateToken,
      multi: true
    },
    provideHttpClient(withInterceptors([authInterceptor])),
  ],
};
