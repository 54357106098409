import {
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { SchedulerService } from '../../services/scheduler.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FloatLabel } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { Schedule } from '../../models/schedule.struct';
import { FluidModule } from 'primeng/fluid';
import { IftaLabelModule } from 'primeng/iftalabel';
import { ButtonModule } from 'primeng/button';
import {
  SchedulerActions,
  SchedulerFeature,
} from '../../state/scheduler.feature';
import { SelectModule } from 'primeng/select';
import { DropDownItem } from '../../util/api';
import { RippleModule } from 'primeng/ripple';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-schedule-form',
  standalone: true,
  imports: [
    FloatLabel,
    InputTextModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    IftaLabelModule,
    FluidModule,
    ButtonModule,
    SelectModule,
    RippleModule,
  ],
  templateUrl: './schedule-form.component.html',
  styleUrl: './schedule-form.component.scss',
})
export class ScheduleFormComponent implements OnChanges {
  @Input() schedule!: Schedule;
  public schedulerService = inject(SchedulerService);
  private store = inject(Store);
  private messageService = inject(MessageService);
  name = new FormControl('');
  intervalMin = new FormControl('');
  intervalMax = new FormControl('');
  exitStrategy!: DropDownItem;

  exitStrategies = [
    { name: 'Close Client', code: 'Close Client' },
    { name: 'Stay in lobby', code: 'Stay in lobby' },
    { name: 'Wait and close', code: 'Wait and close' },
    { name: "Execute another task", code: "Execute another task" }
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['schedule'] && changes['schedule'].currentValue) {
      this.name.setValue(this.schedule.name);
      this.exitStrategy = new DropDownItem(
        this.schedule.exit_strategy,
        this.schedule.exit_strategy
      );
      this.intervalMin.setValue(this.schedule.interval_min.toString());
      this.intervalMax.setValue(this.schedule.interval_max.toString());
    }
  }

  saveSchedule(): void {
    this.schedulerService
      .updateSchedule({
        id: this.schedule.id,
        name: this.name.value!,
        interval_min: Number(this.intervalMin.value!),
        interval_max: Number(this.intervalMax.value!),
        exit_strategy: this.exitStrategy.code,
        tasks: [],
      })
      .subscribe({
        next: (respSchedule: any) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: `Saved ${this.schedule.name}` });
          this.store.dispatch(
            SchedulerActions.updateScheduleSuccess({ schedule: respSchedule })
          );
        },
        error: (err: any) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: `Failed to save ${this.schedule.name}` });
          this.store.dispatch(
            SchedulerActions.updateScheduleFailure({ error: err })
          );
        },
      });
  }

  deleteSchedule(): void {
    this.schedulerService.deleteSchedule(this.schedule.id).subscribe({
      next: (r: any) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: `Deleted ${this.schedule.name}` });
        if (r.success === "LAST") {
          this.schedulerService.getMySchedules().subscribe((r: any) => {
            this.store.dispatch(SchedulerActions.set({ schedules: r }));
          });
        }
        this.store.dispatch(
          SchedulerActions.deleteScheduleSuccess({ id: this.schedule.id })
        );
      },
      error: (err: any) =>
        this.store.dispatch(
          SchedulerActions.deleteScheduleFailure({ error: err })
        ),
    });
  }
}
