import { inject, Injectable } from '@angular/core';
import { UserService } from '../services/user.service';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { UserFeature, UserFeatureActions } from './user.feature';
import { of } from 'rxjs';

@Injectable()
export class UserEffects {
  actions$ = inject(Actions);
  userService = inject(UserService);

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserFeatureActions.loadUser),
      mergeMap((action) => {
        return this.userService.getUserById(action.userId).pipe(
          map((user) => UserFeatureActions.loadUserSuccess({ user })),
          catchError((error) => {
            console.log(error.error);
            return of(UserFeatureActions.loadUserFailure({ error }));
          })
        );
      })
    )
  );
}
