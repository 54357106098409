import { Injectable } from '@angular/core';
import { BaseStyle } from 'primeng/base';

const theme = ({ dt }) => `
.p-datepicker {
    position: relative;
    display: inline-flex;
    max-width: 100%;
}

.p-datepicker-input {
    flex: 1 1 auto;
    width: 1%;
}

.p-datepicker:has(.p-datepicker-dropdown) .p-datepicker-input {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
}

.p-datepicker-dropdown {
    cursor: pointer;
    display: inline-flex;
    user-select: none;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: ${dt('datepicker.dropdown.width')};
    border-start-end-radius: ${dt('datepicker.dropdown.border.radius')};
    border-end-end-radius: ${dt('datepicker.dropdown.border.radius')};
    background: ${dt('datepicker.dropdown.background')};
    border: 1px solid ${dt('datepicker.dropdown.border.color')};
    border-inline-start: 0 none;
    color: ${dt('datepicker.dropdown.color')};
    transition: background ${dt('datepicker.transition.duration')}, color ${dt('datepicker.transition.duration')}, border-color ${dt('datepicker.transition.duration')}, outline-color ${dt('datepicker.transition.duration')};
    outline-color: transparent;
}

.p-datepicker-dropdown:not(:disabled):hover {
    background: ${dt('datepicker.dropdown.hover.background')};
    border-color: ${dt('datepicker.dropdown.hover.border.color')};
    color: ${dt('datepicker.dropdown.hover.color')};
}

.p-datepicker-dropdown:not(:disabled):active {
    background: ${dt('datepicker.dropdown.active.background')};
    border-color: ${dt('datepicker.dropdown.active.border.color')};
    color: ${dt('datepicker.dropdown.active.color')};
}

.p-datepicker-dropdown:focus-visible {
    box-shadow: ${dt('datepicker.dropdown.focus.ring.shadow')};
    outline: ${dt('datepicker.dropdown.focus.ring.width')} ${dt('datepicker.dropdown.focus.ring.style')} ${dt('datepicker.dropdown.focus.ring.color')};
    outline-offset: ${dt('datepicker.dropdown.focus.ring.offset')};
}

.p-datepicker:has(.p-datepicker-input-icon-container) {
    position: relative;
}

.p-datepicker:has(.p-datepicker-input-icon-container) .p-datepicker-input {
    padding-inline-end: calc((${dt('form.field.padding.x')} * 2) + ${dt('icon.size')});
}

.p-datepicker-input-icon-container {
    cursor: pointer;
    position: absolute;
    top: 50%;
    inset-inline-end: ${dt('form.field.padding.x')};
    margin-top: calc(-1 * (${dt('icon.size')} / 2));
    color: ${dt('datepicker.input.icon.color')};
    line-height: 1;
}

.p-datepicker-fluid {
    display: flex;
}

.p-datepicker-fluid .p-datepicker-input {
    width: 1%;
}

.p-datepicker .p-datepicker-panel {
    min-width: 100%;
}

.p-datepicker-panel {
    width: auto;
    padding: ${dt('datepicker.panel.padding')};
    background: ${dt('datepicker.panel.background')};
    color: ${dt('datepicker.panel.color')};
    border: 1px solid ${dt('datepicker.panel.border.color')};
    border-radius: ${dt('datepicker.panel.border.radius')};
    box-shadow: ${dt('datepicker.panel.shadow')};
}

.p-datepicker-panel-inline {
    display: inline-block;
    overflow-x: auto;
    box-shadow: none;
}

.p-datepicker-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${dt('datepicker.header.padding')};
    background: ${dt('datepicker.header.background')};
    color: ${dt('datepicker.header.color')};
    border-bottom: 1px solid ${dt('datepicker.header.border.color')};
}

.p-datepicker-next-button:dir(rtl) {
    transform: rotate(180deg);
}

.p-datepicker-prev-button:dir(rtl) {
    transform: rotate(180deg);
}

.p-datepicker-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${dt('datepicker.title.gap')};
    font-weight: ${dt('datepicker.title.font.weight')};
}

.p-datepicker-select-year,
.p-datepicker-select-month {
    border: none;
    background: transparent;
    margin: 0;
    cursor: pointer;
    font-weight: inherit;
    transition: background ${dt('datepicker.transition.duration')}, color ${dt('datepicker.transition.duration')}, border-color ${dt('datepicker.transition.duration')}, outline-color ${dt('datepicker.transition.duration')}, box-shadow ${dt(
        'datepicker.transition.duration'
    )};
}

.p-datepicker-select-month {
    padding: ${dt('datepicker.select.month.padding')};
    color: ${dt('datepicker.select.month.color')};
    border-radius: ${dt('datepicker.select.month.border.radius')};
}

.p-datepicker-select-year {
    padding: ${dt('datepicker.select.year.padding')};
    color: ${dt('datepicker.select.year.color')};
    border-radius: ${dt('datepicker.select.year.border.radius')};
}

.p-datepicker-select-month:enabled:hover {
    background: ${dt('datepicker.select.month.hover.background')};
    color: ${dt('datepicker.select.month.hover.color')};
}

.p-datepicker-select-year:enabled:hover {
    background: ${dt('datepicker.select.year.hover.background')};
    color: ${dt('datepicker.select.year.hover.color')};
}

.p-datepicker-calendar-container {
    display: flex;
}

.p-datepicker-calendar-container .p-datepicker-calendar {
    flex: 1 1 auto;
    border-inline-start: 1px solid ${dt('datepicker.group.border.color')};
    padding-inline: ${dt('datepicker.group.gap')};
}

.p-datepicker-calendar-container .p-datepicker-calendar:first-child {
    padding-inline-start: 0;
    border-inline-start: 0 none;
}

.p-datepicker-calendar-container .p-datepicker-calendar:last-child {
    padding-inline-end: 0;
}

.p-datepicker-day-view {
    width: 100%;
    border-collapse: collapse;
    font-size: 1rem;
    margin: ${dt('datepicker.day.view.margin')};
}

.p-datepicker-weekday-cell {
    padding: ${dt('datepicker.week.day.padding')};
}

.p-datepicker-weekday {
    font-weight: ${dt('datepicker.week.day.font.weight')};
    color: ${dt('datepicker.week.day.color')};
}

.p-datepicker-day-cell {
    padding: ${dt('datepicker.date.padding')};
}

.p-datepicker-day {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: ${dt('datepicker.date.width')};
    height: ${dt('datepicker.date.height')};
    border-radius: ${dt('datepicker.date.border.radius')};
    transition: background ${dt('datepicker.transition.duration')}, color ${dt('datepicker.transition.duration')}, border-color ${dt('datepicker.transition.duration')},
        box-shadow ${dt('datepicker.transition.duration')}, outline-color ${dt('datepicker.transition.duration')};
    border: 1px solid transparent;
    outline-color: transparent;
    color: ${dt('datepicker.date.color')};
}

.p-datepicker-day:not(.p-datepicker-day-selected):not(.p-disabled):hover {
    background: ${dt('datepicker.date.hover.background')};
    color: ${dt('datepicker.date.hover.color')};
}

.p-datepicker-day:focus-visible {
    box-shadow: ${dt('datepicker.date.focus.ring.shadow')};
    outline: ${dt('datepicker.date.focus.ring.width')} ${dt('datepicker.date.focus.ring.style')} ${dt('datepicker.date.focus.ring.color')};
    outline-offset: ${dt('datepicker.date.focus.ring.offset')};
}

.p-datepicker-day-selected {
    background: ${dt('datepicker.date.selected.background')};
    color: ${dt('datepicker.date.selected.color')};
}

.p-datepicker-day-selected-range {
    background: ${dt('datepicker.date.range.selected.background')};
    color: ${dt('datepicker.date.range.selected.color')};
}

.p-datepicker-today > .p-datepicker-day {
    background: ${dt('datepicker.today.background')};
    color: ${dt('datepicker.today.color')};
}

.p-datepicker-today > .p-datepicker-day-selected {
    background: ${dt('datepicker.date.selected.background')};
    color: ${dt('datepicker.date.selected.color')};
}

.p-datepicker-today > .p-datepicker-day-selected-range {
    background: ${dt('datepicker.date.range.selected.background')};
    color: ${dt('datepicker.date.range.selected.color')};
}

.p-datepicker-weeknumber {
    text-align: center
}

.p-datepicker-month-view {
    margin: ${dt('datepicker.month.view.margin')};
}

.p-datepicker-month {
    width: 33.3%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    padding: ${dt('datepicker.month.padding')};
    transition: background ${dt('datepicker.transition.duration')}, color ${dt('datepicker.transition.duration')}, border-color ${dt('datepicker.transition.duration')}, box-shadow ${dt('datepicker.transition.duration')}, outline-color ${dt(
        'datepicker.transition.duration'
    )};
    border-radius: ${dt('datepicker.month.border.radius')};
    outline-color: transparent;
    color: ${dt('datepicker.date.color')};
}

.p-datepicker-month:not(.p-disabled):not(.p-datepicker-month-selected):hover {
    color:  ${dt('datepicker.date.hover.color')};
    background: ${dt('datepicker.date.hover.background')};
}

.p-datepicker-month-selected {
    color: ${dt('datepicker.date.selected.color')};
    background: ${dt('datepicker.date.selected.background')};
}

.p-datepicker-month:not(.p-disabled):focus-visible {
    box-shadow: ${dt('datepicker.date.focus.ring.shadow')};
    outline: ${dt('datepicker.date.focus.ring.width')} ${dt('datepicker.date.focus.ring.style')} ${dt('datepicker.date.focus.ring.color')};
    outline-offset: ${dt('datepicker.date.focus.ring.offset')};
}

.p-datepicker-year-view {
    margin: ${dt('datepicker.year.view.margin')};
}

.p-datepicker-year {
    width: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    padding: ${dt('datepicker.year.padding')};
    transition: background ${dt('datepicker.transition.duration')}, color ${dt('datepicker.transition.duration')}, border-color ${dt('datepicker.transition.duration')}, box-shadow ${dt('datepicker.transition.duration')}, outline-color ${dt(
        'datepicker.transition.duration'
    )};
    border-radius: ${dt('datepicker.year.border.radius')};
    outline-color: transparent;
    color: ${dt('datepicker.date.color')};
}

.p-datepicker-year:not(.p-disabled):not(.p-datepicker-year-selected):hover {
    color: ${dt('datepicker.date.hover.color')};
    background: ${dt('datepicker.date.hover.background')};
}

.p-datepicker-year-selected {
    color: ${dt('datepicker.date.selected.color')};
    background: ${dt('datepicker.date.selected.background')};
}

.p-datepicker-year:not(.p-disabled):focus-visible {
    box-shadow: ${dt('datepicker.date.focus.ring.shadow')};
    outline: ${dt('datepicker.date.focus.ring.width')} ${dt('datepicker.date.focus.ring.style')} ${dt('datepicker.date.focus.ring.color')};
    outline-offset: ${dt('datepicker.date.focus.ring.offset')};
}

.p-datepicker-buttonbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:  ${dt('datepicker.buttonbar.padding')};
    border-top: 1px solid ${dt('datepicker.buttonbar.border.color')};
}

.p-datepicker-buttonbar .p-button {
    width: auto;
}

.p-datepicker-time-picker {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid ${dt('datepicker.time.picker.border.color')};
    padding: 0;
    gap: ${dt('datepicker.time.picker.gap')};
}

.p-datepicker-calendar-container + .p-datepicker-time-picker {
    padding: ${dt('datepicker.time.picker.padding')};
}

.p-datepicker-time-picker > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: ${dt('datepicker.time.picker.button.gap')};
}

.p-datepicker-time-picker span {
    font-size: 1rem;
}

.p-datepicker-timeonly .p-datepicker-time-picker {
    border-top: 0 none;
}

.p-datepicker-calendar:not(:first-child):not(:last-child) .p-datepicker-header {
    justify-content: center;
}

.p-datepicker:has(.p-inputtext-sm) .p-datepicker-dropdown {
    width: ${dt('datepicker.dropdown.sm.width')};
}

.p-datepicker:has(.p-inputtext-sm) .p-datepicker-dropdown .p-icon,
.p-datepicker:has(.p-inputtext-sm) .p-datepicker-input-icon {
    font-size: ${dt('form.field.sm.font.size')};
    width: ${dt('form.field.sm.font.size')};
    height: ${dt('form.field.sm.font.size')};
}

.p-datepicker:has(.p-inputtext-lg) .p-datepicker-dropdown {
    width: ${dt('datepicker.dropdown.lg.width')};
}

.p-datepicker:has(.p-inputtext-lg) .p-datepicker-dropdown .p-icon,
.p-datepicker:has(.p-inputtext-lg) .p-datepicker-input-icon {
    font-size: ${dt('form.field.lg.font.size')};
    width: ${dt('form.field.lg.font.size')};
    height: ${dt('form.field.lg.font.size')};
}

/* For PrimeNG */

p-calendar.ng-invalid.ng-dirty .p-datepicker.p-inputwrapper .p-inputtext{
    border-color: ${dt('inputtext.invalid.border.color')};
}

p-datepicker.ng-invalid.ng-dirty .p-datepicker.p-inputwrapper .p-inputtext{
    border-color: ${dt('inputtext.invalid.border.color')};
}
`;

const inlineStyles = {
    root: ({ props }) => ({ position: props.appendTo === 'self' ? 'relative' : undefined })
};

const classes = {
    root: ({ instance }) => ({
        'p-datepicker p-component p-inputwrapper': true,
        'p-datepicker-fluid': instance.hasFluid,
        'p-inputwrapper-filled': instance.filled,
        'p-inputwrapper-focus': instance.focus,
        'p-focus': instance.focus || instance.overlayVisible
    }),
    pcInput: 'p-datepicker-input',
    dropdown: 'p-datepicker-dropdown',
    inputIconContainer: 'p-datepicker-input-icon-container',
    inputIcon: 'p-datepicker-input-icon',
    panel: ({ instance }) => ({
        'p-datepicker-panel p-component': true,
        'p-datepicker-panel-inline': instance.inline,
        'p-disabled': instance.disabled,
        'p-datepicker-timeonly': instance.timeOnly
    }),
    calendarContainer: 'p-datepicker-calendar-container',
    calendar: 'p-datepicker-calendar',
    header: 'p-datepicker-header',
    pcPrevButton: 'p-datepicker-prev-button',
    title: 'p-datepicker-title',
    selectMonth: 'p-datepicker-select-month',
    selectYear: 'p-datepicker-select-year',
    decade: 'p-datepicker-decade',
    pcNextButton: 'p-datepicker-next-button',
    dayView: 'p-datepicker-day-view',
    weekHeader: 'p-datepicker-weekheader p-disabled',
    weekNumber: 'p-datepicker-weeknumber',
    weekLabelContainer: 'p-datepicker-weeklabel-container p-disabled',
    weekDayCell: 'p-datepicker-weekday-cell',
    weekDay: 'p-datepicker-weekday',
    dayCell: ({ date }) => [
        'p-datepicker-day-cell',
        {
            'p-datepicker-other-month': date.otherMonth,
            'p-datepicker-today': date.today
        }
    ],
    day: ({ instance, date }) => {
        let selectedDayClass = '';

        if (instance.isRangeSelection() && instance.isSelected(date) && date.selectable) {
            selectedDayClass = date.day === instance.value[0].getDate() || date.day === instance.value[1].getDate() ? 'p-datepicker-day-selected' : 'p-datepicker-day-selected-range';
        }

        return {
            'p-datepicker-day': true,
            'p-datepicker-day-selected': !instance.isRangeSelection() && instance.isSelected(date) && date.selectable,
            'p-disabled': instance.disabled || !date.selectable,
            [selectedDayClass]: true
        };
    },
    monthView: 'p-datepicker-month-view',
    month: ({ instance, props, month, index }) => [
        'p-datepicker-month',
        {
            'p-datepicker-month-selected': instance.isMonthSelected(index),
            'p-disabled': props.disabled || !month.selectable
        }
    ],
    yearView: 'p-datepicker-year-view',
    year: ({ instance, props, year }) => [
        'p-datepicker-year',
        {
            'p-datepicker-year-selected': instance.isYearSelected(year.value),
            'p-disabled': props.disabled || !year.selectable
        }
    ],
    timePicker: 'p-datepicker-time-picker',
    hourPicker: 'p-datepicker-hour-picker',
    pcIncrementButton: 'p-datepicker-increment-button',
    pcDecrementButton: 'p-datepicker-decrement-button',
    separator: 'p-datepicker-separator',
    minutePicker: 'p-datepicker-minute-picker',
    secondPicker: 'p-datepicker-second-picker',
    ampmPicker: 'p-datepicker-ampm-picker',
    buttonbar: 'p-datepicker-buttonbar',
    pcTodayButton: 'p-datepicker-today-button',
    pcClearButton: 'p-datepicker-clear-button'
};

@Injectable()
export class DatePickerStyle extends BaseStyle {
    name = 'datepicker';

    theme = theme;

    classes = classes;

    inlineStyles = inlineStyles;
}

/**
 *
 * DatePicker is a form component to work with dates.
 *
 * [Live Demo](https://www.primeng.org/datepicker/)
 *
 * @module datepickerstyle
 *
 */
export enum DatePickerClasses {
    /**
     * Class name of the root element
     */
    root = 'p-datepicker',
    /**
     * Class name of the input element
     */
    pcInput = 'p-datepicker-input',
    /**
     * Class name of the dropdown element
     */
    dropdown = 'p-datepicker-dropdown',
    /**
     * Class name of the input icon container element
     */
    inputIconContainer = 'p-datepicker-input-icon-container',
    /**
     * Class name of the input icon element
     */
    inputIcon = 'p-datepicker-input-icon',
    /**
     * Class name of the panel element
     */
    panel = 'p-datepicker-panel',
    /**
     * Class name of the calendar container element
     */
    calendarContainer = 'p-datepicker-calendar-container',
    /**
     * Class name of the calendar element
     */
    calendar = 'p-datepicker-calendar',
    /**
     * Class name of the header element
     */
    header = 'p-datepicker-header',
    /**
     * Class name of the previous button element
     */
    pcPrevButton = 'p-datepicker-prev-button',
    /**
     * Class name of the title element
     */
    title = 'p-datepicker-title',
    /**
     * Class name of the select month element
     */
    selectMonth = 'p-datepicker-select-month',
    /**
     * Class name of the select year element
     */
    selectYear = 'p-datepicker-select-year',
    /**
     * Class name of the decade element
     */
    decade = 'p-datepicker-decade',
    /**
     * Class name of the next button element
     */
    pcNextButton = 'p-datepicker-next-button',
    /**
     * Class name of the day view element
     */
    dayView = 'p-datepicker-day-view',
    /**
     * Class name of the week header element
     */
    weekHeader = 'p-datepicker-weekheader',
    /**
     * Class name of the week number element
     */
    weekNumber = 'p-datepicker-weeknumber',
    /**
     * Class name of the week label container element
     */
    weekLabelContainer = 'p-datepicker-weeklabel-container',
    /**
     * Class name of the week day cell element
     */
    weekDayCell = 'p-datepicker-weekday-cell',
    /**
     * Class name of the week day element
     */
    weekDay = 'p-datepicker-weekday',
    /**
     * Class name of the day cell element
     */
    dayCell = 'p-datepicker-day-cell',
    /**
     * Class name of the day element
     */
    day = 'p-datepicker-day',
    /**
     * Class name of the month view element
     */
    monthView = 'p-datepicker-month-view',
    /**
     * Class name of the month element
     */
    month = 'p-datepicker-month',
    /**
     * Class name of the year view element
     */
    yearView = 'p-datepicker-year-view',
    /**
     * Class name of the year element
     */
    year = 'p-datepicker-year',
    /**
     * Class name of the time picker element
     */
    timePicker = 'p-datepicker-time-picker',
    /**
     * Class name of the hour picker element
     */
    hourPicker = 'p-datepicker-hour-picker',
    /**
     * Class name of the increment button element
     */
    pcIncrementButton = 'p-datepicker-increment-button',
    /**
     * Class name of the decrement button element
     */
    pcDecrementButton = 'p-datepicker-decrement-button',
    /**
     * Class name of the separator element
     */
    separator = 'p-datepicker-separator',
    /**
     * Class name of the minute picker element
     */
    minutePicker = 'p-datepicker-minute-picker',
    /**
     * Class name of the second picker element
     */
    secondPicker = 'p-datepicker-second-picker',
    /**
     * Class name of the ampm picker element
     */
    ampmPicker = 'p-datepicker-ampm-picker',
    /**
     * Class name of the buttonbar element
     */
    buttonbar = 'p-datepicker-buttonbar',
    /**
     * Class name of the today button element
     */
    pcTodayButton = 'p-datepicker-today-button',
    /**
     * Class name of the clear button element
     */
    pcClearButton = 'p-datepicker-clear-button'
}

export interface DatePickerStyle extends BaseStyle {}
