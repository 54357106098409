import { Component, inject, OnInit } from '@angular/core';
import { SchedulerService } from '../../shared/services/scheduler.service';
import { SchedulerActions } from '../../shared/state/scheduler.feature';
import { Store } from '@ngrx/store';
import { AsyncPipe, CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { ScheduleFormComponent } from '../../shared/components/schedule-form/schedule-form.component';
import { DividerModule } from 'primeng/divider';
import { TaskFormComponent } from '../../shared/components/task-form/task-form.component';
import { SdnActions, SdnFeature } from '../../shared/state/sdn.feature';
import { SdnService } from '../../shared/services/sdn.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-scheduler',
  standalone: true,
  imports: [
    AsyncPipe,
    DividerModule,
    CommonModule,
    FormsModule,
    TaskFormComponent,
    AccordionModule,
    ScheduleFormComponent,
    ScheduleFormComponent,
    TaskFormComponent,
  ],
  templateUrl: './scheduler.component.html',
  styleUrl: './scheduler.component.scss',
})
export class SchedulerComponent implements OnInit {
  public selectedSchedule = 0;
  public selectedTask = 0;
  public schedulerService = inject(SchedulerService);
  public sdnService = inject(SdnService);
  private store = inject(Store);
  private messageService = inject(MessageService);
  public scripts$ = this.store.select(SdnFeature.selectScripts);

  ngOnInit(): void {
    this.schedulerService.getMySchedules().subscribe((r: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Schedules loaded successfully' });
      this.store.dispatch(SchedulerActions.set({ schedules: r }));
    });

    this.store.dispatch(SdnActions.loadScripts());
  }

  public changeSelectedSchedule(index: number): void {
    this.selectedSchedule = index;
  }

  public changeSelectedTask(index: number): void {
    this.selectedTask = index;
  }

  public addSchedule(): void {
    this.schedulerService.createSchedule().subscribe((r: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Schedule created successfully' });
      this.store.dispatch(SchedulerActions.insert({ schedule: r }));
    });
  }

  public addTask(scheduleId: number): void {
    this.schedulerService.createTask(scheduleId).subscribe((r: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Task created successfully' });
      this.store.dispatch(
        SchedulerActions.insertTaskIntoSchedule({ scheduleId, task: r })
      );
    });
  }
}
