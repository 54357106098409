export const API_BASE = "https://boobies.doctor/api";

export class DropDownItem {
  name!: string;
  code!: string;

  constructor(name: string, code: string) {
    this.name = name;
    this.code = code;
  }
}

