import { Routes } from '@angular/router';
import { HomeComponent } from './routes/home/home.component';
import { SdnComponent } from './routes/sdn/sdn.component';
import { CheckoutComponent } from './routes/checkout/checkout.component';
import { SchedulerComponent } from './routes/scheduler/scheduler.component';
import { ControlPanelComponent } from './routes/control-panel/control-panel.component';
import { SubscriptionsComponent } from './routes/subscriptions/subscriptions.component';
import { ScripterControlPanelComponent } from './routes/scripter-control-panel/scripter-control-panel.component';

export const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'scripts', component: SdnComponent},
    {path: 'auth',
        loadChildren: () => import('./routes/auth/auth.routes').then(m => m.AUTH_ROUTES)
    },
    {path: 'checkout', component: CheckoutComponent},
    {path: 'control', component: ControlPanelComponent},
    {path: 'scheduler', component: SchedulerComponent},
    {path: 'subscriptions', component: SubscriptionsComponent},
    {path: 'control/scripts', component: ScripterControlPanelComponent},
];
