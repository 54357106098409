import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { CartService } from "../services/cart.service";
import { Cart, CartActions } from "./cart.feature";
import { catchError, map, of, switchMap } from "rxjs";
import { MessageService } from "primeng/api";


@Injectable()
export class CartEffects {
  private actions$ = inject(Actions);
  private cartService = inject(CartService);
  private messageService = inject(MessageService);

  addToCart$ = createEffect(() => this.actions$.pipe(
    ofType(CartActions.addItem),
    switchMap(({ item }) => this.cartService.addToCart(item).pipe(
      map((cart: any) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Item added to cart' });
        return CartActions.set({ cart });
      }),
      catchError((error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to add item to cart' });
        return of(CartActions.setError({ error }));
      })
    ))
  ));

  removeFromCart$ = createEffect(() => this.actions$.pipe(
    ofType(CartActions.removeItem),
    switchMap(({ item }) => this.cartService.removeFromCart(item).pipe(
      map(() => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Item removed from cart' });
        return CartActions.removeItemSuccess({ item });
      }),
      catchError((error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to remove item from cart' });
        return of(CartActions.removeItemError({ error }));
      })
    ))
  ));
}
