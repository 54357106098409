import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { Observable, map } from 'rxjs';
import { AsyncPipe, CommonModule } from '@angular/common';
import { Cart, CartActions, CartFeature } from '../../state/cart.feature';
import { Store } from '@ngrx/store';
import { TableModule } from 'primeng/table';
import { Script } from '../../models/script.struct';
import { ButtonModule } from 'primeng/button';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { StripeService } from 'ngx-stripe';

@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [AsyncPipe, CommonModule, TableModule, ButtonModule],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss'
})
export class CartComponent implements OnInit {
  public stripe: Stripe | null = null;
  public cartService = inject(CartService);
  private stripeService = inject(StripeService);
  private store = inject(Store);

  async ngOnInit(): Promise<void> {
    this.cartService.getCart().subscribe((r: any) => {
      this.store.dispatch(CartActions.set({ cart: r }));
    });

    this.stripe = await loadStripe('pk_test_51ISu3QKP1sH7T3SbUh8i8hb0RkLw7RcZ2SPDFyze2l1Trkdf4t9fnOfpA32eOvAS5fgQ91y50mHR3JPr2yOA9UZV00sAvJbnyI');
  }

  public getTotalPrice(): Observable<number> {
    return this.cartService.items$.pipe(
      map((items: Script[]) => items.reduce((acc, item) => acc + item.price, 0))
    );
  }

  public removeItem(script: Script): void {
    this.store.dispatch(CartActions.removeItem({ item: script.id }));
  }

  redirectToCheckout() {
    try {
      this.getTotalPrice().subscribe(totalPrice => {
        this.cartService.checkout(totalPrice).subscribe((r: any) => {
          if (this.stripe) {
            this.stripe.redirectToCheckout({ sessionId: r.id });
          }
        });
      });
    } catch (error: any) {
      console.error('Error redirecting to Stripe Checkout:', error);
    }
  }
}
