<div class="glass rounded-lg p-4">
    <div class="font-semibold text-3xl col-span-6">
        <span style="font-size: 2rem;" class="text-primary">[</span>
        <span>subscriptions</span>
        <span style="font-size: 2rem;" class="text-primary">]</span>
    </div>

    <div class="my-4">
        <p-divider></p-divider>
    </div>
</div>