import { createActionGroup, createFeature, createReducer, emptyProps, on, props } from "@ngrx/store";
import { Schedule, Task } from "../models/schedule.struct";

export type SchedulerState = {
    schedules: Schedule[],
    error: string | null;
    loading: boolean;
    index: number;
}

const initialState: SchedulerState = {
    schedules: [],
    error: null,
    loading: false,
    index: 3
}

export const SchedulerActions = createActionGroup({
    source: 'SchedulerActions',
    events: {
        'Reset State': emptyProps(),
        'Set': props<{ schedules: any[] }>(),
        'Insert': props<{ schedule: any }>(),
        'Insert Task Into Schedule': props<{ scheduleId: number, task: any }>(),
        'Delete Task': props<{ taskId: number }>(),
        'Delete Task Success': props<{ taskId: number }>(),
        'Delete Task Failure': props<{ error: any }>(),
        'Set Index': props<{ index: number }>(),
        'Load Schedules': emptyProps(),
        'Load Schedules Success': props<{ schedules: any[] }>(),
        'Load Schedules Failure': props<{ error: any }>(),
        'Create Schedule': props<{ schedule: any }>(),
        'Create Schedule Success': props<{ schedule: any }>(),
        'Create Schedule Failure': props<{ error: any }>(),
        'Update Schedule': props<{ schedule: any }>(),
        'Update Schedule Success': props<{ schedule: any }>(),
        'Update Schedule Failure': props<{ error: any }>(),
        'Delete Schedule': props<{ id: number }>(),
        'Delete Schedule Success': props<{ id: number }>(),
        'Delete Schedule Failure': props<{ error: any }>(),
        'Update Task': props<{ task: any }>(),
        'Update Task Success': props<{ task: Task }>(),
        'Update Task Failure': props<{ error: any }>(),
    }
});

export const SchedulerFeature = createFeature({
    name: 'SchedulerFeature',
    reducer: createReducer(
        initialState,
        on(SchedulerActions.resetState, () => initialState),
        on(SchedulerActions.set, (state, { schedules }) => ({
            ...state,
            schedules
        })),
        on(SchedulerActions.insert, (state, { schedule }) => ({
            ...state,
            schedules: [...state.schedules, schedule]
        })),
        on(SchedulerActions.insertTaskIntoSchedule, (state, { scheduleId, task }) => ({
            ...state,
            schedules: state.schedules.map(s => s.id === scheduleId ? { ...s, tasks: [...s.tasks, task] } : s)
        })),
        on(SchedulerActions.deleteTask, (state, { taskId }) => ({
            ...state,
            schedules: state.schedules.map(s => ({ ...s, tasks: s.tasks.filter(t => t.id !== taskId) }))
        })),
        on(SchedulerActions.deleteTaskSuccess, (state, { taskId }) => ({
            ...state,
            schedules: state.schedules.map(s => ({ ...s, tasks: s.tasks.filter(t => t.id !== taskId) }))
        })),
        on(SchedulerActions.deleteTaskFailure, (state, { error }) => ({
            ...state,
            error
        })),
        on(SchedulerActions.setIndex, (state, { index }) => ({
            ...state,
            index: index
        })),
        on(SchedulerActions.loadSchedules, state => ({
            ...state,
            loading: true,
            error: null
        })),
        on(SchedulerActions.loadSchedulesSuccess, (state, { schedules }) => ({
            ...state,
            schedules,
            loading: false
        })),
        on(SchedulerActions.loadSchedulesFailure, (state, { error }) => ({
            ...state,
            error,
            loading: false
        })),
        on(SchedulerActions.createSchedule, state => ({
            ...state,
            loading: true,
            error: null
        })),
        on(SchedulerActions.createScheduleSuccess, (state, { schedule }) => ({
            ...state,
            schedules: [...state.schedules, schedule],
            loading: false
        })),
        on(SchedulerActions.createScheduleFailure, (state, { error }) => ({
            ...state,
            error,
            loading: false
        })),
        on(SchedulerActions.updateSchedule, state => ({
            ...state,
            loading: true,
            error: null
        })),
        on(SchedulerActions.updateScheduleSuccess, (state, { schedule }) => ({
            ...state,
            schedules: state.schedules.map(s => s.id === schedule.id ? schedule : s),
            loading: false
        })),
        on(SchedulerActions.updateScheduleFailure, (state, { error }) => ({
            ...state,
            error,
            loading: false
        })),
        on(SchedulerActions.deleteSchedule, state => ({
            ...state,
            loading: true,
            error: null
        })),
        on(SchedulerActions.deleteScheduleSuccess, (state, { id }) => ({
            ...state,
            schedules: state.schedules.filter(s => s.id !== id),
            loading: false
        })),
        on(SchedulerActions.deleteScheduleFailure, (state, { error }) => ({
            ...state,
            error,
            loading: false
        })),
        on(SchedulerActions.updateTask, state => ({
            ...state,
            loading: true,
            error: null
        })),
        on(SchedulerActions.updateTaskSuccess, (state, { task }) => ({
            ...state,
            schedules: state.schedules.map(s => s.id === task.belongs_to ? { ...s, tasks: s.tasks.map(t => t.id === task.id ? task : t) } : s),
            loading: false
        })),
        on(SchedulerActions.updateTaskFailure, (state, { error }) => ({
            ...state,
            error,
            loading: false
        }))
    )
});