import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { SdnService } from '../../shared/services/sdn.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, Observable, tap } from 'rxjs';
import { ScriptCarouselComponent } from "../../shared/components/script-carousel/script-carousel.component";
import { AsyncPipe, CommonModule } from '@angular/common';
import { Script } from '../../shared/models/script.struct';
import { DividerModule } from 'primeng/divider';
import { Store } from '@ngrx/store';
import { CartActions } from '../../shared/state/cart.feature';
import { InputTextModule } from 'primeng/inputtext';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { SdnActions } from '../../shared/state/sdn.feature';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-sdn',
  standalone: true,
  imports: [ButtonModule, ScriptCarouselComponent, AsyncPipe, CommonModule, DividerModule, DividerModule, InputTextModule, InputGroupModule, InputGroupAddonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './sdn.component.html',
  styleUrl: './sdn.component.scss'
})
export class SdnComponent implements OnInit {
  public sdnService = inject(SdnService);
  private store = inject(Store);
  private destroyRef = inject(DestroyRef);
  public search = new FormControl();
  public latest$!: Observable<Script>;

  ngOnInit(): void {
    this.store.dispatch(SdnActions.loadAllScripts());
  }

  doSearch() {
    this.store.dispatch(SdnActions.searchScripts({ search: this.search.value }));
  }

  // This is duplicated. Bad.
  addToCart(scriptId: number) {
    this.store.dispatch(CartActions.addItem({ item: scriptId }));
  }
}
