import { Component, inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Script } from '../../models/script.struct';
import { DropDownItem } from '../../util/api';
import { FluidModule } from 'primeng/fluid';
import { SelectModule } from 'primeng/select';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FloatLabel, FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { SchedulerService } from '../../services/scheduler.service';
import { SchedulerActions } from '../../state/scheduler.feature';

@Component({
  selector: 'app-task-form',
  standalone: true,
  imports: [
    FluidModule,
    SelectModule,
    FloatLabelModule,
    CommonModule,
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    DividerModule
  ],
  templateUrl: './task-form.component.html',
  styleUrl: './task-form.component.scss'
})
export class TaskFormComponent implements OnInit {
  @Input() task: any;
  @Input() scripts!: Script[];
  public name = new FormControl('');
  public weighting = new FormControl('');
  public minRunTime = new FormControl('');
  public maxRunTime = new FormControl('');
  public dropDownItems!: DropDownItem[];
  public selectedItem: DropDownItem | undefined;
  private store = inject(Store);
  private schedulerService = inject(SchedulerService);

  ngOnInit(): void {
    this.dropDownItems = this.scripts.map((script) => new DropDownItem(script.name, script.name));

    this.name.setValue(this.task.name);
    this.weighting.setValue(this.task.weighting);
    this.minRunTime.setValue(this.task.min_runtime_minutes);
    this.maxRunTime.setValue(this.task.max_runtime_minutes);

    const scriptName = this.task.script?.name || '';

    this.selectedItem = this.dropDownItems.find((item) =>
      item.code && scriptName &&
      item.code.toLowerCase() === scriptName.toLowerCase()
    );

    if (!this.selectedItem) {
      console.warn('No matching script found:', {
        availableScripts: this.dropDownItems.map(item => item.code),
        searchingFor: scriptName
      });
    }
  }

  updateTask(): void {
    this.store.dispatch(SchedulerActions.updateTask({
      task: {
        id: this.task.id,
        script: this.scripts.find((script) => script.name === this.selectedItem?.code)?.id,
        min_runtime_minutes: this.minRunTime.value,
        max_runtime_minutes: this.maxRunTime.value,
        weighting: this.weighting.value,
        name: this.name.value
      }
    }));
  }

  deleteTask(): void {
    this.schedulerService.deleteTask(this.task.id).subscribe(() => {
      this.store.dispatch(SchedulerActions.deleteTask({ taskId: this.task.id }));
    });
  }
}
