<div 
    class="grid grid-cols-6 gap-4 p-4 rounded-lg">
    @if (userService.loggedInUser$ | async; as user)
    {
    <div class="font-semibold text-3xl col-span-6">
        <span style="font-size: 2rem;" class="text-primary">[</span>
        <span>settings</span>
        <span style="font-size: 2rem;" class="text-primary">-></span>
        <span>{{user.username}}</span>
        <span style="font-size: 2rem;" class="text-primary">]</span>
    </div>

    <p-divider class="col-span-6"></p-divider>

    <!-- List of schedules -->
    <div class="col-span-1">
        <div class="flex flex-col space-y-1 pr-2">
            <!-- @for (schedule of schedules; track schedule; let i = $index)
            {
            <div class=" sc px-3 py-1 cursor-pointer transition-all"
                [ngClass]="{'active-sc': i == selectedSchedule}" (click)="changeSelectedSchedule($index)">
                <div class="text-lg">{{schedule.name}}</div>
            </div>
            } -->
        </div>
    </div>

    <div class="col-span-5">
        <div>
            <div class="schedule-form-container">
                <!-- <app-schedule-form [schedule]="schedules[selectedSchedule]"></app-schedule-form> -->
            </div>
            <!-- <p-divider></p-divider> -->
            <div class="tasks__header font-semibold text-2xl mb-2">
            </div>
            <!-- @for (task of schedules[selectedSchedule].tasks; track task; let i = $index)
            {
            @if (scripts$ | async; as scripts)
            {
            <app-task-form [task]="task" [scripts]="scripts"></app-task-form>
            }
            } -->
        </div>
    </div>

    }
</div>