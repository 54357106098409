import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  User,
  UserFeature,
  UserFeatureActions,
} from '../../shared/state/user.feature';
import { Observable } from 'rxjs';
import { AsyncPipe, CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MessageModule } from 'primeng/message';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [AsyncPipe, FormsModule, CommonModule, MessageModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  user$!: Observable<User | null>;
  error$!: Observable<any>;
  private store = inject(Store);

  constructor() {
    this.user$ = this.store.select(UserFeature.selectUser);
    this.error$ = this.store.select(UserFeature.selectError);
  }

  ngOnInit(): void {
    this.user$.subscribe(user => {
      console.log(user);
    });
    this.store.dispatch(UserFeatureActions.loadUser({ userId: 1 }));
  }
}
