<div class="h-full flex flex-col">
    <p-fluid class="flex-1 flex flex-col">
        <div class="grid grid-cols-3 gap-4 w-full h-full">
            <!-- <div class="font-semibold text-3xl col-span-6">
            <span style="font-size: 2rem;" class="text-primary">[</span>
            <span>scp</span>
            <span style="font-size: 2rem;" class="text-primary">-></span>
            <span>{{script.name}}</span>
            <span style="font-size: 2rem;" class="text-primary">-></span>
            <span>config</span>
            <span style="font-size: 2rem;" class="text-primary">]</span>
        </div> -->

            <!-- <p-divider class="col-span-6"></p-divider> -->

            <div class="col-span-1">
                <p-floatlabel variant="on">
                    <input id="maxSlots" pInputText [formControl]="maxSlots" />
                    <label for="maxSlots">Max Slots</label>
                </p-floatlabel>
            </div>

            <div class="col-span-1">
                <p-floatlabel variant="on">
                    <input id="maxSessions" pInputText [formControl]="maxSessions" />
                    <label for="maxSessions">Max Sessions</label>
                </p-floatlabel>
            </div>

            <div class="col-span-1">
                <p-floatlabel variant="on">
                    <input id="maxRuntimeHours" pInputText [formControl]="maxRuntimeHours" />
                    <label for="maxRuntimeHours">Max Runtime Hours</label>
                </p-floatlabel>
            </div>

            <!-- <div class="col-span-1">
                <p-button label="Save" severity="success"></p-button>
            </div>
            <div class="col-span-1">
                <p-button label="Request Review" severity="contrast"></p-button>
            </div> -->
            <!-- <div class="col-span-1">
                <p-button [label]="debugMode.value ? 'Debug Mode: On' : 'Debug Mode: Off'"
                    [severity]="debugMode.value ? 'primary' : 'contrast'" (onClick)="toggleDebugMode()"></p-button>
            </div> -->
        </div>
    </p-fluid>
</div>