import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserService } from '../services/user.service';
import { AuthActions } from './auth.feature';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Injectable()
export class AuthEffects {
  public actions$ = inject(Actions);
  private messageService = inject(MessageService);

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  registerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.register),
      mergeMap((action) => this.registrationHandler(action))
    )
  );

  $loginUser = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      mergeMap((action) => this.loginHandler(action))
    )
  );

  private loginHandler(data: { username: string; password: string }) {
    return this.userService.postLogin(data).pipe(
      map((token) => {
        localStorage.setItem("ILoveModTwisted", token.token);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Logged in successfully' });
        this.router.navigate(['/']);
        // Cheap gay retard hack for bastards
        setTimeout(() => {
          this.userService.hydrateUser();
        }, 300);
        return AuthActions.loginSuccess({ token: token });
      }),
      catchError((error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid username or password' });
        return of(AuthActions.loginFailure({ error }));
      })
    )
  }

  private registrationHandler(data: { username: string; password: string }) {
    return this.userService.postRegistration(data).pipe(
      map((user) => {
        this.router.navigate(['/login']);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Registered successfully' });
        return AuthActions.registerSuccess({ user });
      }),
      catchError((error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Username already taken' });
        return of(AuthActions.registerFailure({ error }));
      })
    );
  }
}
