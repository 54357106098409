<div *ngIf="user$ | async as user; else loadingOrErr">
  <div *ngIf="!(error$ | async)">
    <div *ngIf="user.username" class="text-2xl">{{ user.username }}</div>
  </div>
</div>

<ng-template #loadingOrErr>
  <div *ngIf="error$ | async as error; else loading">
    <p-message severity="error">Error: {{error.error}}</p-message>
  </div>
</ng-template>

<ng-template #loading>
  <p>Loading...</p>
</ng-template>
