import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';
import { AuthFeature, AuthState } from './shared/state/auth.feature';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(Store<{ auth: AuthState }>);
  return store.select(AuthFeature.selectToken).pipe(
    take(1),
    switchMap((token) => {
      if (token) {

        let clonedRequest = undefined;
        if (window.location.href.includes('http://localhost')) {
          clonedRequest = req.clone({
            setHeaders: { Authorization: `Token ${token}` },
            url: req.url.replace('https://boobies.doctor', 'http://localhost:8000')
          });
        } else {
          clonedRequest = req.clone({
            setHeaders: { Authorization: `Token ${token}` },
          });
        }
        return next(clonedRequest);
      }
      return next(req);
    })
  );
};