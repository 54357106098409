import { createActionGroup, createFeature, createReducer, emptyProps, on, props } from "@ngrx/store";
import { Script, ScriptConfig } from "../models/script.struct"

export type SdnState = {
    scripts: Script[];
    error: string | null;
    loading: boolean;
}

const initialState: SdnState = {
    scripts: [],
    error: null,
    loading: false,
}

export const SdnActions = createActionGroup({
    source: 'SdnActions',
    events: {
        // Scripts that this user is subscribed to
        'Load Scripts': emptyProps(),
        'Load Scripts Success': props<{ scripts: Script[] }>(),
        'Load Scripts Failure': props<{ error: any }>(),
        // Search all scripts
        'Search Scripts': props<{ search: string }>(),
        'Search Scripts Success': props<{ scripts: Script[] }>(),
        'Search Scripts Failure': props<{ error: any }>(),
        // All scripts
        'Load All Scripts': emptyProps(),
        'Load All Scripts Success': props<{ scripts: Script[] }>(),
        'Load All Scripts Failure': props<{ error: any }>(),
    }
});

export const SdnFeature = createFeature({
    name: 'SdnFeature',
    reducer: createReducer(
        initialState,
        on(SdnActions.loadScripts, (state) => ({
            ...state,
            loading: true,
            error: null,
        })),
        on(SdnActions.loadScriptsSuccess, (state, { scripts }) => ({
            ...state,
            scripts,
            loading: false,
        })),
        on(SdnActions.loadScriptsFailure, (state, { error }) => ({
            ...state,
            error,
            loading: false,
        })),
        on(SdnActions.searchScripts, (state, { search }) => ({
            ...state,
            search,
        })),
        on(SdnActions.searchScriptsSuccess, (state, { scripts }) => ({
            ...state,
            scripts,
            loading: false,
        })),
        on(SdnActions.searchScriptsFailure, (state, { error }) => ({
            ...state,
            error,
            loading: false,
        })),
        on(SdnActions.loadAllScripts, (state) => ({
            ...state,
            loading: true,
            error: null,
        })),
        on(SdnActions.loadAllScriptsSuccess, (state, { scripts }) => ({
            ...state,
            scripts,
            loading: false,
        })),
        on(SdnActions.loadAllScriptsFailure, (state, { error }) => ({
            ...state,
            error,
            loading: false,
        })),
    )
});

export type SCPState = {
    scripts: Script[];
    error: string | null;
    loading: boolean;
}

const initialSCPState: SCPState = {
    scripts: [],
    error: null,
    loading: false,
}

export const SCPActions = createActionGroup({
    source: 'SCPActions',
    events: {
        'Load Script Configs': emptyProps(),
        'Load Script Configs Success': props<{ scriptConfigs: ScriptConfig[] }>(),
        'Load Script Configs Failure': props<{ error: any }>(),
        'Update Script Config': props<{ scriptConfig: ScriptConfig }>(),
        'Update Script Config Success': props<{ scriptConfig: ScriptConfig }>(),
        'Update Script Config Failure': props<{ error: any }>(),
        'Load Authored Scripts': emptyProps(),
        'Load Authored Scripts Success': props<{ scripts: Script[] }>(),
        'Load Authored Scripts Failure': props<{ error: any }>(),
    }
});

export const SCPFeature = createFeature({
    name: 'SCPFeature',
    reducer: createReducer(
        initialSCPState,
        on(SCPActions.loadScriptConfigs, (state) => ({
            ...state,
            loading: true,
            error: null,
        })),
        on(SCPActions.loadScriptConfigsSuccess, (state, { scriptConfigs }) => ({
            ...state,
            scriptConfigs,
            loading: false,
        })),
        // on(SCPActions.loadScriptConfigsFailure, (state, { error }) => ({
        //     ...state,
        //     error,
        //     loading: false,
        // })),
        // on(SCPActions.updateScriptConfig, (state, { scriptConfig }) => ({
        //     ...state,
        //     scriptConfigs: state.scriptConfigs.map(sc => sc.id === scriptConfig.id ? scriptConfig : sc),
        // })),
        // on(SCPActions.updateScriptConfigSuccess, (state, { scriptConfig }) => ({
        //     ...state,
        //     scriptConfigs: state.scriptConfigs.map(sc => sc.id === scriptConfig.id ? scriptConfig : sc),
        // })),
        on(SCPActions.updateScriptConfigFailure, (state, { error }) => ({
            ...state,
            error,
            loading: false,
        })),
        on(SCPActions.loadAuthoredScripts, (state) => ({
            ...state,
            loading: true,
            error: null,
        })),
        on(SCPActions.loadAuthoredScriptsSuccess, (state, { scripts }) => ({
            ...state,
            scripts,
            loading: false,
        })),
        on(SCPActions.loadAuthoredScriptsFailure, (state, { error }) => ({
            ...state,
            error,
            loading: false,
        })),
    ),
});
