import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { API_BASE } from '../util/api';
import { Cart, CartFeature } from '../state/cart.feature';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private http = inject(HttpClient);
  private store = inject(Store);
  readonly items$ = this.store.select(CartFeature.selectItems);

  public getCart(): Observable<Cart>{
    return this.http.get<Cart>(`${API_BASE}/cart/`);
  }

  public addToCart(id: number) {
    return this.http.post(`${API_BASE}/cart/`, { "script_id": id });
  }

  public removeFromCart(id: number) {
    return this.http.patch(`${API_BASE}/cart/`, { "script_id": id });
  }

  public checkout(amount: number) {
    return this.http.post(`${API_BASE}/payment/`, { amount });
  }
}
