import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './shared/components/header/header.component';
import { definePreset } from '@primeng/themes';
import { PrimeNG } from 'primeng/config';
import { Store } from '@ngrx/store';
import { AuthActions } from './shared/state/auth.feature';
import { UserService } from './shared/services/user.service';
import { HotkeysDirective, HotkeysService } from '@ngneat/hotkeys';
// import Aura from '@primeng/themes/Aura';
import Lara from '@primeng/themes/lara';
import { ToastModule } from 'primeng/toast';

const shitLanguage = definePreset(Lara, {
  semantic: {
    primary: {
      50: '{sky.50}',
      100: '{sky.100}',
      200: '{sky.200}',
      300: '{sky.300}',
      400: '{sky.400}',
      500: '{sky.500}',
      600: '{sky.600}',
      700: '{sky.700}',
      800: '{sky.800}',
      900: '{sky.900}',
      950: '{sky.950}'
    }
  }
});

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, HotkeysDirective, ToastModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  private readonly pngConf = inject(PrimeNG);
  private readonly store = inject(Store);
  private router = inject(Router);
  private hotkeyService = inject(HotkeysService);
  private userService = inject(UserService);

  constructor() {
    this.pngConf.theme.set({ preset: shitLanguage });
    this.pngConf.ripple.set(true);
    this.rehydrateAuthToken();
    this.hydrateUser();
  }

  ngOnInit(): void {
    this.hotkeyService.addSequenceShortcut({ keys: 'g>c', description: 'Go to scp' }).subscribe(() => {
      this.router.navigate(['/control/scripts']);
    });

    this.hotkeyService.addSequenceShortcut({ keys: 'g>s', description: 'Go to scripts' }).subscribe(() => {
      this.router.navigate(['/scripts']);
    });

    this.hotkeyService.addSequenceShortcut({ keys: 'g>d>l', description: 'Go to login' }).subscribe(() => {
      this.router.navigate(['/auth/login']);
    });

    this.hotkeyService.addSequenceShortcut({ keys: 'g>d>r', description: 'Go to registration' }).subscribe(() => {
      this.router.navigate(['/auth/register']);
    });

    this.hotkeyService.addSequenceShortcut({ keys: 'g>d>c', description: 'Go to cart' }).subscribe(() => {
      this.router.navigate(['/checkout']);
    });

    this.userService.loggedInUser$.subscribe((user) => {
      if (user.username === "Moriarty") {
        this.hotkeyService.addSequenceShortcut({ keys: 'r>r', description: 'dev reload.' }).subscribe(() => {
          window.location.reload();
        });
      }
    });

    const token = localStorage.getItem('token');
    const userStr = localStorage.getItem('user');
    
    if (token && userStr) {
      try {
        const user = JSON.parse(userStr);
        this.store.dispatch(AuthActions.setToken({ token }));
        this.store.dispatch(AuthActions.setUser({ user }));
      } catch (e) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      }
    }
  }


  private rehydrateAuthToken(): void {
    const token = localStorage.getItem('ILoveModTwisted');
    if (token) {
      this.store.dispatch(AuthActions.setToken({ token }));
    }
  }

  private hydrateUser(): void {
    // TODO: Error handling
    this.userService.getSelf().subscribe((user: any) => {
      this.store.dispatch(AuthActions.setUser({ user }));
    });
  }
}
