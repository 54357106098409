import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SdnService } from '../../shared/services/sdn.service';
import { SCPActions } from '../../shared/state/sdn.feature';
import { AsyncPipe, CommonModule } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { FormsModule } from '@angular/forms';
import { SplitterModule } from 'primeng/splitter';
import { ScriptConfigComponent } from '../../shared/components/script-config/script-config.component';

@Component({
  selector: 'app-scripter-control-panel',
  standalone: true,
  imports: [AsyncPipe, DividerModule, FormsModule, CommonModule, SplitterModule, ScriptConfigComponent],
  templateUrl: './scripter-control-panel.component.html',
  styleUrl: './scripter-control-panel.component.scss'
})
export class ScripterControlPanelComponent implements OnInit {
  private store = inject(Store);
  public sdnService = inject(SdnService);
  public selectedScript = 0;

  ngOnInit(): void {
    this.store.dispatch(SCPActions.loadAuthoredScripts());
  }

  changeSelectedScript(index: number): void {
    this.selectedScript = index;
  }
}
