import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { SchedulerService } from "../services/scheduler.service";
import { SchedulerActions } from "./scheduler.feature";
import { map, mergeMap, catchError, of } from "rxjs";
import { MessageService } from "primeng/api";

@Injectable()
export class SchedulerEffects {
  private actions$ = inject(Actions);
  private schedulerService = inject(SchedulerService);
  private messageService = inject(MessageService);

  updateTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.updateTask),
      mergeMap(({ task }) => 
        this.schedulerService.updateTask(task).pipe(
          map(() => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Task updated successfully' });
            return SchedulerActions.updateTaskSuccess({ task });
          }),
          catchError((error) => of(SchedulerActions.updateTaskFailure({ error })))
        )
      )
    )
  );
}
