import { createActionGroup, createFeature, createReducer, emptyProps, on, props } from "@ngrx/store";

export type User = {
    id: number;
    username: string;
    is_scripter: boolean;
}

export type UserFeatureState = {
    user: User | null,
    error: string | null
}

const initialState: UserFeatureState = {
    user: null,
    error: null
};

export const UserFeatureActions = createActionGroup({
    source: 'UserFeature',
    events: {
        'Reset State': emptyProps(),
        'Set': props<{ user: User }>(),
        'Register': props<{ username: string; password: string }>(),
        // TODO: Full user model
        'Register Success': props<{ user: any }>(),
        'Register Failure': props<{ error: any }>(),
        'Load User': props<{ userId: number }>(),
        'Load User Success': props<{ user: User }>(),
        'Load User Failure': props<{ error: any }>()
    }
});

export const UserFeature = createFeature({
    name: 'UserFeature',
    reducer: createReducer(
        initialState,
        on(UserFeatureActions.resetState, () => initialState),
        on(UserFeatureActions.set, (state, { user }) => ({
            ...state,
            user
        })),
        on(UserFeatureActions.loadUserSuccess, (state, { user }) => ({
            ...state,
            user,
            error: null
        })),
        on(UserFeatureActions.loadUserFailure, (state, { error }) => ({
            ...state,
            error
        })),
    ),
});