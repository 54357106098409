import { createActionGroup, createFeature, createReducer, emptyProps, on, props } from "@ngrx/store";

export type Cart = {
    belongs_to: number;
    last_updated: string;
    items: any[];
}

const initialState: Cart = {
    belongs_to: 0,
    last_updated: '',
    items: []
}

export const CartActions = createActionGroup({
    source: 'CartActions',
    events: {
        'Reset State': emptyProps(),
        'Set': props<{ cart: Cart }>(),
        'Add Item': props<{ item: number }>(),
        'Remove Item': props<{ item: number }>(),
        'Remove Item Success': props<{ item: number }>(),
        'Remove Item Error': props<{ error: string }>(),
        'Update Item': props<{ item: number }>(),
        'Set Coupon': props<{ coupon: number }>(),
        'Clear Cart': emptyProps(),
        'Set Error': props<{ error: string }>(),
    }
})

export const CartFeature = createFeature({
    name: 'CartFeature',
    reducer: createReducer(
        initialState,
        on(CartActions.resetState, () => initialState),
        on(CartActions.set, (state, { cart }) => ({
            ...state,
            ...cart
        })),
        on(CartActions.addItem, (state, { item }) => ({
            ...state,
            items: [...state.items, item]
        })),
        on(CartActions.removeItem, (state, { item }) => ({
            ...state,
            items: state.items.filter(i => i !== item)
        })),
        on(CartActions.updateItem, (state, { item }) => ({
            ...state,
            items: state.items.map(i => i === item ? item : i)
        })),
        on(CartActions.setCoupon, (state, { coupon }) => ({
            ...state,
            coupon
        })),
        on(CartActions.clearCart, () => initialState),
        on(CartActions.setError, (state, { error }) => ({
            ...state,
            error
        })),
        on(CartActions.removeItemSuccess, (state, { item }) => ({
            ...state,
            items: state.items.filter(i => i.id !== item)
        })),
        on(CartActions.removeItemError, (state, { error }) => ({
            ...state,
            error
        })) 
    )
})