<header>
    <nav class="p-4  border-gray-200 drop-shadow-xl">
        <div class="nav-content w-4/5 2xl:w-3/5 3xl:w-2/5 mx-auto flex flex-row justify-between">

            <!-- Left content -->
            <div class="nav-left flex flex-row">
                <!-- Logo and adjacent text. -->
                <div class="logo-and-name flex flex-row cursor-pointer" routerLink="/">
                    <div class="HELLFIRE_RAGE_COCKS pt-1.5">
                        <img src="assets/logo.webp" width="40">
                    </div>
                    <div class="text-2xl mr-4 lg:mr-8  font-semibold">
                        Bot<span class="text-primary">With</span>Us
                    </div>
                </div>

                <!-- Header items -->
                <div class="nav-items flex space-x-2 lg:space-x-8 pt-1.5 cursor-pointer">
                    @for (item of items; track item)
                    {
                        @if (item.requiresAuth && userService.isLoggedIn() || !item.requiresAuth) {
                            <div 
                                class="text-md hover:text-primary h-full"
                                [routerLink]="item.routesTo"
                                routerLinkActive="text-primary"
                                [routerLinkActiveOptions]="{exact: true}"
                            >
                                <span [class]="item.icon"></span>
                                <span class="pl-1">{{item.label}}</span>
                            </div>
                        }
                    }
                </div>
            </div>

            <!-- Right content -->
            <div class="nav-right flex space-x-2 lg:space-x-8 pt-1.5">
                <div class="text-md hover:text-primary cursor-pointer" 
                     routerLink="checkout"
                     routerLinkActive="text-primary"
                     [routerLinkActiveOptions]="{exact: true}">
                    <span class="pi pi-shopping-cart"></span>
                    <span class="pl-1">Cart</span>
                </div>
                @if (!(userService.loggedInUser$ | async)) {
                    <div>
                        <button pRipple pButton severity="contrast" class="font-semibold"
                            routerLink="auth/register">Register</button>
                    </div>
                    <div>
                        <button pRipple pButton class="font-semibold" routerLink="auth/login">Login</button>
                    </div>
                } @else {
                    <div class="text-md hover:text-primary cursor-pointer" routerLink="control">
                        <span class="pi pi-user"></span>
                        <span class="pl-1">{{ (userService.loggedInUser$ | async)?.username }}</span>
                    </div>
                }
            </div>
        </div>
    </nav>
</header>

