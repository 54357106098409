import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AsyncPipe } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { UserService } from '../../shared/services/user.service';

@Component({
  selector: 'app-control-panel',
  standalone: true,
  imports: [AsyncPipe, DividerModule],
  templateUrl: './control-panel.component.html',
  styleUrl: './control-panel.component.scss'
})
export class ControlPanelComponent {
  private store = inject(Store);
  public userService = inject(UserService);
}
