import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { SdnService } from '../services/sdn.service';
import { SCPActions, SdnActions } from './sdn.feature';
import { MessageService } from 'primeng/api';

@Injectable()
export class SdnEffects {
    private actions$ = inject(Actions);
    private messageService = inject(MessageService);
    private sdnService = inject(SdnService);

    loadScripts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SdnActions.loadScripts),
            mergeMap(() =>
                this.sdnService.getMySubscriptions().pipe(
                    map((scripts) => SdnActions.loadScriptsSuccess({ scripts })),
                    catchError((error) => of(SdnActions.loadScriptsFailure({ error })))
                )
            )
        )
    );

    loadAllScripts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SdnActions.loadAllScripts),
            mergeMap(() => this.sdnService.getAllScripts().pipe(map(scripts => SdnActions.loadAllScriptsSuccess({ scripts }))))
        )
    );

    loadAuthoredScripts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SCPActions.loadAuthoredScripts),
            mergeMap(() =>
                this.sdnService.getScripterControlList().pipe(
                    map((scripts) => {
                        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Authored scripts loaded successfully' });
                        return SCPActions.loadAuthoredScriptsSuccess({ scripts });
                    }),
                    catchError((error) => {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to load authored scripts' });
                        return of(SCPActions.loadAuthoredScriptsFailure({ error }));
                    })
                )
            )
        )
    );

    searchScripts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SdnActions.searchScripts),
            mergeMap(({ search }) =>
                this.sdnService.searchScripts(search).pipe(
                    map(( scripts ) => {
                        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Search successful' });
                        return SdnActions.searchScriptsSuccess({ scripts });
                    }),
                    catchError((error) => {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Search failed' });
                        return of(SdnActions.searchScriptsFailure({ error }));
                    })
                )
            )
        )
    );
}
