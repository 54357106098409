<div 
    class="grid grid-cols-4 gap-4 bwu-ch-border p-8"
    *ngIf="(cartService.items$ | async) as items"
>
    <p-table 
        [value]="items"
        styleClass="p-datatable-sm"
        class="col-span-3"
    >
    <ng-template #header>
        <tr>
            <th class="bwu-th">Script</th>
            <th class="bwu-th">Author</th>
            <th class="bwu-th">Price</th>
            <th class="bwu-th"></th>
        </tr>
    </ng-template>
    <ng-template #body let-product>
        <tr>
            <td class="bwu-th">{{ product.name}}</td>
            <td class="bwu-th">{{ product.author }}</td>
            <td class="bwu-th">{{ product.price | currency }}</td>
            <td class="bwu-th w-10">
                <div class="flex justify-center items-center" (click)="removeItem(product)">
                    <span class="pi pi-minus-circle hover:text-red-500 hover:cursor-pointer"></span>
                </div>
            </td>
        </tr>
    </ng-template>
    </p-table>

    <div class="flex flex-col">
        <span class="text-md italic">Coupon savings: $0.00</span>
        <span class="text-md italic">Promo savings: $0.00</span>
        <span class="text-md font-bold">Total: {{getTotalPrice() | async | currency:'USD':'symbol':'1.2-2'}}</span>

        <div class="flex mt-auto gap-4">
            <button pButton type="button" label="Coinbase" class="p-button-primary"></button>
            <button pButton type="button" label="Stripe" class="p-button-primary" (click)="redirectToCheckout()"></button>
        </div>
    </div>
</div>